























import { SfButton, SfInput, SfLoader } from '@storefront-ui/vue';
import { computed, ref, defineComponent, nextTick } from '@nuxtjs/composition-api';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCart } from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'CouponCode',
  components: {
    SfButton,
    SfInput,
    SfLoader
  },
  setup() {
    const { cart, applyCoupon, removeCoupon, error, } = useCart();
    const couponCodeUserInput = ref('');
    const couponInputRef = ref(null);
    const loading = ref(false);
    const inputBorder = ref(false);
    const couponCodeAppliedToCart = computed(() => cartGetters.getAppliedCoupon(cart.value)?.code);
    const isCouponCodeApplied = computed(() => couponCodeAppliedToCart.value !== undefined);
    const hasAnyError = computed(() => Object.values(error.value).some((value) => value !== null));
    const applyCouponMsg = computed<Error>(() => error.value.applyCoupon || error.value.removeCoupon || { message: '', name: 'apply-coupon' });
    const applyOrRemoveCoupon = async () => {
      loading.value = true;
      const operationPromise = isCouponCodeApplied.value ? removeCoupon({}) : applyCoupon({ couponCode: couponCodeUserInput.value, currentCode: couponCodeUserInput.value });
      await operationPromise;
      couponCodeUserInput.value = '';
      loading.value = false;
    };
    const removeCouponFromCart = async (coupon) => {
      loading.value = true;
      if(isCouponCodeApplied.value) {
       let couponsList =  couponCodeAppliedToCart.value.split(',');

        let index = couponsList.indexOf(coupon);
        if (index > -1) {
          couponsList.splice(index, 1);
        }
        let newlist = couponsList.join();
  
        const operationPromise = applyCoupon({ couponCode: newlist, currentCode: coupon });
        await operationPromise;
        couponCodeUserInput.value = '';

      }
      loading.value = false;
    };

    const applyCoupons = async () => {
      loading.value = true;
      if(couponCodeUserInput.value) {
        if(couponCodeAppliedToCart.value) {
          let existingCoupon =  couponCodeAppliedToCart.value.split(',');
          let newCoupon =  couponCodeUserInput.value.split(',');
          let mergeCoupon = [...existingCoupon, ...newCoupon];     
          let newlist = [...new Set(mergeCoupon)];
          let couponList = newlist.join();
          const operationPromise = applyCoupon({ couponCode: couponList, currentCode: couponCodeUserInput.value });          
          await operationPromise;
        }else{
          const operationPromise = applyCoupon({ couponCode: couponCodeUserInput.value, currentCode: couponCodeUserInput.value });          
          await operationPromise;
        }
        couponCodeUserInput.value = '';
      } else {
        inputBorder.value = true;
        await nextTick();
        if (couponInputRef.value) {
          couponInputRef.value.focus();
        }
      }
      loading.value = false;
    };

    return {
      couponCodeUserInput,
      couponCodeAppliedToCart,
      isCouponCodeApplied,
      applyCouponMsg,
      applyOrRemoveCoupon,
      removeCouponFromCart,
      applyCoupons,
      hasAnyError,
      loading,
      couponInputRef
    };
  },
});
